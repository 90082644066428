@import url(//fonts.googleapis.com/css2?family=Martel:wght@300;400;600;700;800&display=swap);
@import url(//fonts.googleapis.com/css2?family=Open + Sans:wght@300;400;600;700&display=swap);
@import url(//fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap);
h1 {
  font-size: 96px;
  font-weight: 300;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h2 {
  font-size: 60px;
  font-weight: 300;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h3 {
  font-size: 48px;
  font-weight: 400;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h4 {
  font-size: 34px;
  font-weight: 400;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h5 {
  font-size: 24px;
  font-weight: 500;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h6 {
  font-size: 20px;
  font-weight: 600;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

p {
  font-size: 14px;
  font-weight: 400;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

body {
  background: #fff;
  height: 100%;
  font-family: "Martel", "Open Sans", "Lato", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body > #root > div {
  height: calc(100vh - 8px);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.goback-button {
  border: none;
  background: none;
  font-size: 16px;
  color: #555;
  font-weight: 500;
  margin-bottom: 24px;
}

.goback-button:hover {
  color: #347bff;
}