
.notion {
    font-size: 16px;
    line-height: 1.5;
    color: rgb(55, 53, 47);
    caret-color: rgb(55, 53, 47);
    font-family: "Lato";
    padding: 0;
  }
  
  .notion > *,
  .notion-page > *,
  .notion-column > * {
    padding: 3px 0px;
  }
  
  .notion * {
    box-sizing: border-box;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
  
  .notion-red {
    color: rgb(224, 62, 62);
  }
  .notion-pink {
    color: rgb(173, 26, 114);
  }
  .notion-blue {
    color: rgb(11, 110, 153);
  }
  .notion-purple {
    color: rgb(105, 64, 165);
  }
  .notion-teal {
    color: rgb(15, 123, 108);
  }
  .notion-yellow {
    color: rgb(223, 171, 1);
  }
  .notion-orange {
    color: rgb(217, 115, 13);
  }
  .notion-brown {
    color: rgb(100, 71, 58);
  }
  .notion-gray {
    color: rgb(155, 154, 151);
  }
  .notion-red_background {
    background-color: rgb(251, 228, 228);
  }
  .notion-pink_background {
    background-color: rgb(244, 223, 235);
  }
  .notion-blue_background {
    background-color: rgb(221, 235, 241);
  }
  .notion-purple_background {
    background-color: rgb(234, 228, 242);
  }
  .notion-teal_background {
    background-color: rgb(221, 237, 234);
  }
  .notion-yellow_background {
    background-color: rgb(251, 243, 219);
  }
  .notion-orange_background {
    background-color: rgb(250, 235, 221);
  }
  .notion-brown_background {
    background-color: rgb(233, 229, 227);
  }
  .notion-gray_background {
    background-color: rgb(235, 236, 237);
  }
  .notion-red_background_co {
    background-color: rgb(251, 228, 228, 0.3);
  }
  .notion-pink_background_co {
    background-color: rgb(244, 223, 235, 0.3);
  }
  .notion-blue_background_co {
    background-color: rgb(221, 235, 241, 0.3);
  }
  .notion-purple_background_co {
    background-color: rgb(234, 228, 242, 0.3);
  }
  .notion-teal_background_co {
    background-color: rgb(221, 237, 234, 0.3);
  }
  .notion-yellow_background_co {
    background-color: rgb(251, 243, 219, 0.3);
  }
  .notion-orange_background_co {
    background-color: rgb(250, 235, 221, 0.3);
  }
  .notion-brown_background_co {
    background-color: rgb(233, 229, 227, 0.3);
  }
  .notion-gray_background_co {
    background-color: rgb(235, 236, 237, 0.3);
  }
  
  .notion b {
    font-weight: 600;
  }
  
  .notion-title {
    font-size: 2.5em;
    font-weight: 700;
    margin-top: 0.75em;
    margin-bottom: 0.25em;
    display: none;
  }
  
  .notion-h1,
  .notion-h2,
  .notion-h3 {
    line-height: 1.3;
    padding: 3px 2px;
  }
  
  .notion-h1 {
    font-family: "Martel";
    font-size: 2.2em;
    font-weight: 700;
    margin-top: 1.4em;
  }
  .notion-h1:first-child {
    margin-top: 0;
  }
  .notion-h2 {
    font-weight: 600;
    font-size: 1.5em;
    margin-top: 1.1em;
  }
  .notion-h3 {
    font-weight: 500;
    font-size: 1.25em;
    margin-top: 1em;
  }
  .notion-emoji {
    font-family: "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
      "Segoe UI Symbol";
  }
  .notion-page-cover {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 30vh;
    min-height: 30vh;
    padding: 0;
  }
  
  .notion-page {
    padding: 0;
    margin: 0 auto;
    max-width: 708px;
    width: 100%;
  }

  .notion-asset-wrapper iframe {
    border: none;
    width: 100%;
    height: calc(1248px * 0.5625);
    background-color: #000;
    border-radius: 10px;
  }

  @media screen and (max-width: 1248px) { 
    .notion-asset-wrapper iframe {
      border: none;
      width: 100%;
      height: calc((100vw - 32px) * 0.5625);
      background-color: #000;
      border-radius: 10px;
    }
  }

  @media only screen and (max-width: 576px){
    .notion {
      padding: 0;
    }
  }
  @media only screen and (max-width: 730px) {
    .notion-page {
      /* padding: 0 2vw; */
    }
  }
  
  .notion-page-offset {
    /* margin-top: 96px; */
  }
  
  span.notion-page-icon-cover {
    height: 78px;
    width: 78px;
    font-size: 78px;
    display: inline-block;
    line-height: 1.1;
    margin-left: 0px;
  }
  
  span.notion-page-icon-offset {
    margin-top: -42px;
  }
  
  img.notion-page-icon-cover {
    border-radius: 3px;
    width: 124px;
    height: 124px;
    margin: 8px;
  }
  
  img.notion-page-icon-offset {
    margin-top: -80px;
  }
  
  .notion-full-width {
    /* padding: 0 40px; */
    max-width: 100%;
  }
  
  .notion-small-text {
    font-size: 14px;
  }
  .notion-quote {
    font-family: "Martel";
    white-space: pre-wrap;
    word-break: break-word;
    border-left: 3px solid #cdcdcd;
    padding: 0.2em 0.9em;
    margin: 0;
    font-size: 1.0em;
  }
  .notion-hr {
    margin: 6px 0px;
    padding: 0;
    border-top-width: 1px;
    border-bottom-width: 0;
    border-color: rgba(55, 53, 47, 0.09);
  }
  .notion-link {
    color: inherit;
    word-break: break-word;
    text-decoration: underline;
    text-decoration-color: inherit;
  }
  .notion-blank {
    min-height: 1rem;
    padding: 3px 2px;
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .notion-page-link {
    display: flex;
    color: rgb(55, 53, 47);
    text-decoration: none;
    height: 30px;
    margin: 1px 0px;
    transition: background 120ms ease-in 0s;
  }
  .notion-page-link:hover {
    background: rgba(55, 53, 47, 0.08);
  }
  
  .notion-page-icon {
    line-height: 1.4;
    margin-right: 4px;
    margin-left: 2px;
  }
  img.notion-page-icon {
    display: block;
    object-fit: cover;
    border-radius: 3px;
    width: 20px;
    height: 20px;
  }
  
  .notion-icon {
    display: block;
    width: 18px;
    height: 18px;
    color: rgba(55, 53, 47, 0.4);
  }
  
  .notion-page-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    line-height: 1.3;
    border-bottom: 1px solid rgba(55, 53, 47, 0.16);
    margin: 1px 0px;
  }
  
  .notion-inline-code {
    color: #eb5757;
    padding: 0.2em 0.4em;
    background: rgba(135, 131, 120, 0.15);
    border-radius: 3px;
    font-size: 85%;
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
      monospace;
  }
  
  .notion-list {
    margin: 0;
    margin-block-start: 0.6em;
    margin-block-end: 0.6em;
  }
  
  .notion-list-disc {
    list-style-type: disc;
    padding-inline-start: 1.7em;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .notion-list-numbered {
    list-style-type: decimal;
    padding-inline-start: 1.6em;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .notion-list-disc li {
    padding-left: 0.1em;
  }
  
  .notion-list-numbered li {
    padding-left: 0.2em;
  }
  
  .notion-list li {
    padding: 6px 0px;
    white-space: pre-wrap;
  }
  
  .notion-asset-wrapper {
    margin: 0.5rem auto 0.5rem;
    /* max-width: 100%; */
    width: 100% !important;
    height: auto; 

  }
  
  .notion-asset-wrapper > div {
    width: 100%;
    padding-bottom: 0% !important;
  }

  .notion-asset-wrapper > img {
    max-width: 100%;
  }
    
  .notion-text {
    font-size: 16px;
    white-space: pre-wrap;
    caret-color: rgb(55, 53, 47);
    padding: 3px 2px;
  }
  .notion-block {
    padding: 3px 2px;
  }
  
  .notion .notion-code {
    font-size: 85%;
  }
  
  .notion-code {
    padding: 30px 16px 30px 20px;
    margin: 4px 0;
    border-radius: 3px;
    tab-size: 2;
    display: block;
    box-sizing: border-box;
    overflow-x: scroll;
    background: rgb(247, 246, 243);
    font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
      monospace;
  }
  
  .notion-column {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  
  .notion-column > *:first-child {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  
  .notion-column > *:last-child {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  
  .notion-row {
    display: flex;
    overflow: hidden;
  }
  
  .notion-bookmark {
    margin: 4px 0;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    border: 1px solid rgba(55, 53, 47, 0.16);
    border-radius: 3px;
    display: flex;
    overflow: hidden;
    user-select: none;
  }
  
  .notion-bookmark > div:first-child {
    flex: 4 1 180px;
    padding: 12px 14px 14px;
    overflow: hidden;
    text-align: left;
    color: rgb(55, 53, 47);
  }
  
  .notion-bookmark-title {
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 24px;
    margin-bottom: 2px;
    visibility: none;
  }
  
  .notion-bookmark-description {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
    height: 32px;
    overflow: hidden;
  }
  
  .notion-bookmark-link {
    display: flex;
    margin-top: 6px;
  }
  
  .notion-bookmark-link > img {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 6px;
  }
  
  .notion-bookmark-link > div {
    font-size: 12px;
    line-height: 16px;
    color: rgb(55, 53, 47);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .notion-bookmark-image {
    flex: 1 1 180px;
    position: relative;
  }
  
  .notion-bookmark-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .notion-column .notion-bookmark-image {
    display: none;
  }
  
  @media (max-width: 640px) {
    .notion-bookmark-image {
      display: none;
    }
  
    .notion-row {
      flex-direction: column;
    }
  
    .notion-row > *,
    .notion-column > * {
      width: 100% !important;
    }
  }
  
  .notion-spacer:last-child {
    display: none;
  }
  
  .notion-image-inset {
    /* position: absolute; */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .notion-image-caption {
    padding: 6px 0px;
    white-space: pre-wrap;
    word-break: break-word;
    caret-color: rgb(55, 53, 47);
    font-size: 14px;
    line-height: 1.4;
    color: rgba(55, 53, 47, 0.6);
  }
  
  .notion-callout {
    padding: 16px 16px 16px 12px;
    display: inline-flex;
    width: 100%;
    border-radius: 3px;
    border-width: 1px;
    align-items: center;
    box-sizing: border-box;
    margin: 4px 0;
  }
  .notion-callout-text {
    margin-left: 8px;
    white-space: pre-line;
  }
  
  .notion-toggle {
    padding: 3px 2px;
  }
  .notion-toggle > summary {
    cursor: pointer;
    outline: none;
  }
  .notion-toggle > div {
    margin-left: 1.1em;
  }
  
  .notion-table,
  .notion-th,
  .notion-td {
    border: 1px solid rgba(55, 53, 47, 0.09);
    border-collapse: collapse;
  }
  
  .notion-table {
    width: 100%;
    border-left: none;
    border-right: none;
    border-spacing: 0px;
    white-space: nowrap;
  }
  
  .notion-th,
  .notion-td {
    font-weight: normal;
    padding: 0.25em 0.5em;
    line-height: 1.5;
    min-height: 1.5em;
    text-align: left;
    font-size: 14px;
  }
  
  .notion-td.notion-bold {
    font-weight: 500;
  }
  
  .notion-th {
    color: rgba(55, 53, 47, 0.6);
    font-size: 14px;
  }
  
  .notion-td:first-child,
  .notion-th:first-child {
    border-left: 0;
  }
  
  .notion-td:last-child,
  .notion-th:last-child {
    border-right: 0;
  }
  
  .notion-gallery {
    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-auto-rows: 1fr;
    gap: 16px;
    border-top: 1px solid rgba(55, 53, 47, 0.16);
    padding-top: 16px;
    padding-bottom: 4px;
  }
  .notion-gallery-card {
    display: block;
    color: inherit;
    text-decoration: none;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px,
      rgba(15, 15, 15, 0.1) 0px 2px 4px;
    border-radius: 3px;
    background: white;
    overflow: hidden;
    transition: background 100ms ease-out 0s;
    position: static;
    height: 100%;
  }
  
  .notion-gallery-content {
    padding: 8px 10px 6px;
    font-size: 12px;
    white-space: nowrap;
  }
  
  .notion-gallery-data.is-first {
    white-space: nowrap;
    word-break: break-word;
    caret-color: rgb(55, 53, 47);
    font-size: 14px;
    line-height: 1.5;
    min-height: 21px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .notion-page-header {
    display: none !important;
    position: sticky;
    top: 0;
    width: 100%;
    max-width: 100vw;
    height: 45px;
    min-height: 45px;
    display: flex;
    background: #fff;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    text-size-adjust: 100%;
    line-height: 1.5;
    line-height: 1.2;
    font-size: 14px;
  }
  
  .notion-nav-breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex-grow: 0;
    min-width: 0;
    margin-right: 8px;
  }
  
  .notion-nav-breadcrumb {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  
    color: rgb(55, 53, 47);
    text-decoration: none;
    margin: 1px 0px;
    padding: 4px 6px;
    border-radius: 3px;
    transition: background 120ms ease-in 0s;
    user-select: none;
    background: transparent;
    cursor: pointer;
  }
  
  img.notion-nav-icon {
    width: 18px !important;
    height: 18px !important;
  }
  
  .notion-nav-icon {
    font-size: 18px;
    margin-right: 6px;
    line-height: 1.1;
    color: #000;
  }
  
  .notion-nav-breadcrumb:not(.notion-nav-breadcrumb-active):hover {
    background: rgba(55, 53, 47, 0.08);
  }
  
  .notion-nav-breadcrumb:not(.notion-nav-breadcrumb-active):active {
    background: rgba(55, 53, 47, 0.16);
  }
  
  .notion-nav-breadcrumb.notion-nav-breadcrumb-active {
    cursor: default;
  }
  
  .notion-nav-spacer {
    margin: 0 2px;
    color: rgba(55, 53, 47, 0.4);
  }